import Rebase from 're-base';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Exported from Firebase app's Settings -> General (using the "web" app 'cotd')
const firebaseConfig = {
  apiKey: 'AIzaSyCn2PKUwIzAJjwYfPiPYC1j0U4XiRCNd5o',
  authDomain: 'brians-catch-of-the-day.firebaseapp.com',
  databaseURL: 'https://brians-catch-of-the-day.firebaseio.com',
  //   projectId: 'brians-catch-of-the-day',
  //   storageBucket: 'brians-catch-of-the-day.appspot.com',
  //   messagingSenderId: '475344549711',
  //   appId: '1:475344549711:web:338c98eff28f2541abb73a',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// Rebase provides the real-time data mirroring
const base = Rebase.createClass(firebaseApp.database());

// named export
export { firebaseApp };

// default export
export default base;
