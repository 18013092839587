import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Order from './Order';
import Inventory from './Inventory';
import wesFaveFishes from '../sample-fishes';
import Fish from './Fish';
import base from '../base';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

class App extends React.Component {
  state = {
    fishes: {},
    order: {},
  };

  static propTypes = {
    match: PropTypes.object,
  };

  componentDidMount() {
    const { params } = this.props.match;
    const localStorageOrder = localStorage.getItem(`${params.storeId}/Order`);
    if (localStorageOrder) {
      this.setState({ order: JSON.parse(localStorageOrder) });
    }
    this.ref = base.syncState(`${params.storeId}/fishes`, {
      context: this,
      state: 'fishes',
    });
  }
  componentDidUpdate() {
    localStorage.setItem(
      `${this.props.match.params.storeId}/Order`,
      JSON.stringify(this.state.order)
    );
  }

  componentWillUnmount() {
    base.removeBinding(this.ref);
  }

  addFish = fish => {
    // 1. Make a copy of state
    const fishes = { ...this.state.fishes };
    // 2. Modify the copy
    fishes[`fish${Date.now()}`] = fish;
    // 3. Now set this new object to State
    this.setState({ fishes });
  };

  updateFish = (key, fish) => {
    const fishes = { ...this.state.fishes, [key]: fish };
    this.setState({ fishes: fishes });
  };

  deleteFish = key => {
    const fishes = { ...this.state.fishes };
    fishes[key] = null; // Can't do: delete fishes[key]; b/c of Re-base
    this.setState({ fishes: fishes });
  };

  addToOrder = fishKey => {
    const order = { ...this.state.order };
    order[fishKey] = order[fishKey] + 1 || 1;
    this.setState({ order });
  };

  removeFromOrder = fishKey => {
    const order = { ...this.state.order };
    delete order[fishKey];
    this.setState({ order });
  };

  loadSampleFishes = () => {
    this.setState({ fishes: wesFaveFishes });
  };

  render() {
    return (
      <div className="catch-of-the-day">
        <div className="menu">
          <Header tagline="Always Fresh & Yummy" />
          <TransitionGroup component={'ul'} className="fishes">
            {Object.keys(this.state.fishes).map(key => (
              <CSSTransition
                classNames="fish"
                key={key}
                timeout={{ enter: 500, exit: 500 }}
              >
                <Fish
                  key={key}
                  addToOrder={() => this.addToOrder(key)}
                  details={this.state.fishes[key]}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
        <Order
          fishes={this.state.fishes}
          order={this.state.order}
          removeFromOrder={this.removeFromOrder}
        />
        <Inventory
          addFish={this.addFish}
          loadSampleFishes={this.loadSampleFishes}
          updateFish={this.updateFish}
          removeFish={this.deleteFish}
          fishes={this.state.fishes}
          storeId={this.props.match.params.storeId}
        />
      </div>
    );
  }
}

export default App;
