import React from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../helpers';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

class Order extends React.Component {
  static propTypes = {
    removeFromOrder: PropTypes.func,
    fishes: PropTypes.objectOf(PropTypes.object),
    order: PropTypes.objectOf(PropTypes.number),
  };

  renderOrder = key => {
    const fish = this.props.fishes[key];

    // Make sure the fish is loaded before we display any order data
    if (!fish) return null;
    const count = this.props.order[key];
    const isAvailable = fish && fish.status === 'available';
    if (!count) return null;
    if (isAvailable) {
      return (
        <CSSTransition
          classNames="order"
          key={key}
          timeout={{ enter: 500, exit: 500 }}
        >
          <li key={key}>
            <span>
              <TransitionGroup component="span" className="count">
                <CSSTransition
                  classNames="count"
                  key={count}
                  timeout={{ enter: 500, exit: 500 }}
                >
                  <span>{count}</span>
                </CSSTransition>
              </TransitionGroup>
              lbs {fish.name}
              {formatPrice(count * fish.price)}
              <button onClick={() => this.props.removeFromOrder(key)}>
                &times;
              </button>
            </span>
          </li>
        </CSSTransition>
      );
    } else {
      return (
        <CSSTransition classNames="order" timeout={{ enter: 250, exit: 250 }}>
          <li key={key}>
            Sorry, {fish ? fish.name : 'fish'} no longer available
          </li>
        </CSSTransition>
      );
    }
  };
  render() {
    const orderIDs = Object.keys(this.props.order);
    const totalCost = orderIDs.reduce((accum, key) => {
      const fish = this.props.fishes[key];
      const fishCount = this.props.order[key];
      const isAvail = fish && fish.status === 'available';
      if (isAvail) {
        return accum + fishCount * fish.price;
      }
      return accum;
    }, 0);
    return (
      <div className="order-wrap">
        <h2>Order</h2>
        <TransitionGroup component="ul" className="order">
          {orderIDs && orderIDs.map(this.renderOrder)}
        </TransitionGroup>
        <div className="total">
          Total:
          <strong>{formatPrice(totalCost)}</strong>
        </div>
      </div>
    );
  }
}

export default Order;
