import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import AddFishForm from './AddFishForm';
import EditFishForm from './EditFishForm';
import Login from './Login';
import base, { firebaseApp } from '../base';

class Inventory extends React.Component {
  state = {
    currUID: null,
    owner: null,
  };

  static propTypes = {
    fishes: PropTypes.objectOf(PropTypes.object),
    updateFish: PropTypes.func,
    removeFish: PropTypes.func,
    addFish: PropTypes.func,
    loadSampleFishes: PropTypes.func,
    storeId: PropTypes.string,
  };

  componentDidMount() {
    // Is the user logged-in?
    firebaseApp.auth().onAuthStateChanged(user => {
      if (user) {
        // authHandler expects an auth object (which contains the user) rather than the user object, so wrap user in an object
        this.authHandler({ user });
      }
    });
  }

  authHandler = async authData => {
    console.group('authHandler');
    console.log(authData);

    // Look up the current store in Firebase db
    const storeBase = await base.fetch(this.props.storeId, { context: this });
    console.info('Store info: ', storeBase);

    console.info(
      'UID: ',
      authData.user.uid,
      ' Email: ',
      authData.user.email,
      ' Display Name: ',
      authData.user.displayName
    );
    // If store has no owner, then save it as our own
    if (!storeBase.owner) {
      base.post(`${this.props.storeId}/owner`, { data: authData.user.uid });
    }

    // Set the component's state to reflect the current user
    this.setState({
      currUID: authData.user.uid,
      owner: storeBase.owner || authData.user.uid,
    });

    console.groupEnd();
  };

  authenticate = providerName => {
    console.log(`Logging in with ${providerName}`);
    const authProvider = new firebase.auth[`${providerName}AuthProvider`]();
    firebaseApp
      .auth()
      .signInWithPopup(authProvider)
      .then(this.authHandler)
      .catch(error => {
        console.info(
          `Error in Firebase.auth.signInWithPopup: ${error}  Code: ${error.code}`
        );
        alert(`Auth error: ${error.code}\n\n${error}`);
      });
  };

  logout = async event => {
    await firebaseApp.auth().signOut();
    this.setState({ currUID: null });
  };

  render() {
    const logoutButton = <button onClick={this.logout}>Log Out!</button>;

    // Check if user is logged-in
    if (!this.state.currUID) {
      return <Login authenticate={this.authenticate} />;
    }
    // Check that logged-in user is the owner of the store
    if (this.state.currUID !== this.state.owner) {
      return (
        <div>
          <p>Sorry, you are not the store owner</p>
          {logoutButton}
        </div>
      );
    }
    // Logged-in user must be the owner, so render the inventory
    return (
      <div className="inventory">
        <h2>Inventory</h2>
        {logoutButton}
        {Object.keys(this.props.fishes).map(key => (
          <EditFishForm
            fish={this.props.fishes[key]}
            fishKey={key}
            updateFish={this.props.updateFish}
            removeFish={this.props.removeFish}
            key={key}
          />
        ))}
        <AddFishForm addFish={this.props.addFish} />
        <button onClick={this.props.loadSampleFishes}>Load Samples</button>
      </div>
    );
  }
}

export default Inventory;
